import { useForm } from "react-hook-form";
import { Col, Row } from "antd";

import { Container, CardLogin } from "../Login/styles";
import colors from "core/resources/theme/colors";
import history from "infra/helpers/history";
import useRegister from "core/features/auth/register/useRegister";
import Field from "ui/components/Input/Field";
import { Button } from "@chakra-ui/button";
import { isValidEmail } from "@brazilian-utils/brazilian-utils";

function RegisterPage() {
  const { control, handleSubmit } = useForm<{
    nome: any;
    username: any;
    password: any;
  }>({
    reValidateMode: "onChange",
  });

  const { isLoading: isLoadingRegister, handleRegister } = useRegister();

  return (
    <Container>
      <CardLogin>
        <form onSubmit={(ev) => handleSubmit(handleRegister)(ev)}>
          <h2>Seja bem vindo</h2>
          <p>
            Ja tem conta?{" "}
            <span
              onClick={() => history.push("/auth/login")}
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                color: colors.primary,
              }}
            >
              Faça o login
            </span>
          </p>
          <br />
          <Row gutter={16}>
            <Col sm={24}>
              <Field
                label="Nome"
                control={control}
                name="nome"
                rules={{
                  required: {
                    message: "Informe seu nome",
                    value: true,
                  },
                }}
              />
            </Col>
          </Row>

          <br />
          <Row gutter={16}>
            <Col sm={24}>
              <Field
                label="Email"
                control={control}
                name="username"
                rules={{
                  required: {
                    message: "Informe o e-mail",
                    value: true,
                  },
                  validate: (v) => isValidEmail(v) || "Email inválido",
                }}
              />
            </Col>
          </Row>

          <br />

          <Row gutter={16}>
            <Col sm={24}>
              <Field
                label="Senha"
                inputProps={{
                  type: "password",
                }}
                control={control}
                name="password"
                rules={{
                  required: {
                    message: "Informe a senha",
                    value: true,
                  },
                }}
              />
            </Col>
          </Row>

          <br />

          <div>
            <Row gutter={16}>
              <Col sm={24}>
                <Button
                  isLoading={isLoadingRegister}
                  disabled={isLoadingRegister}
                  type="submit"
                  isFullWidth
                >
                  Cadastrar
                </Button>
              </Col>
            </Row>

            {/* <div
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                ou
              </div> */}

            {/* <Row gutter={16} style={{ marginTop: 16 }}>
                <Col sm={24}>
                  <Button
                    block
                    loading={isLoading || isLoadingRegister}
                    disabled={isLoading || isLoadingRegister}
                    color="blue"
                    onClick={() => handleLoginFacebook()}
                  >
                    <Icon
                      icon="facebook-official"
                      style={{ color: "white", marginRight: 16 }}
                    />
                    Facebook
                  </Button>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: 16 }}>
                <Col sm={24}>
                  <Button
                    block
                    loading={isLoading || isLoadingRegister}
                    disabled={isLoading || isLoadingRegister}
                    color="red"
                    onClick={() => handleLoginGoogle()}
                  >
                    <Icon
                      icon="google-plus-circle"
                      style={{ color: "white", marginRight: 16 }}
                    />
                    Google Plus
                  </Button>
                </Col>
              </Row> */}
          </div>
        </form>
      </CardLogin>
    </Container>
  );
}

export default RegisterPage;
