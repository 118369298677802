import { Text, Skeleton } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import { FaBuilding } from "react-icons/fa";
import CardDefault from "ui/components/Cards/CardDefault";

interface CardResultadosProps {
  name: string;
  complete: boolean;
  description?: string;
  icon?: any;
  bg?: string;
  value: number | string;
  isLoading?: boolean;
}

function CardResultDash({
  name,
  description,
  complete,
  icon,
  bg,
  value,
  isLoading,
}: CardResultadosProps) {
  if (isLoading) {
    return <Skeleton h="110px" w="100%" mb="5" mr="5" rounded="16px" />;
  }

  return (
    <CardDefault shadow="xs" cursor="pointer">
      <Flex justifyContent="center" alignItems="center">
        <Flex flexDir="column">
          <Flex>
            <Flex
              w="50px"
              h="50px"
              rounded="50%"
              justifyContent="center"
              alignItems="center"
              bg={bg ? bg : complete ? colors.green100 : colors.orange100}
            >
              {!icon ? (
                <FaBuilding
                  fontSize="18px"
                  color={complete ? colors.green : colors.orange}
                />
              ) : (
                icon
              )}
            </Flex>
            <Flex
              fontWeight="bold"
              fontSize={28}
              ml="4"
              alignItems="center"
              color={colors.black900}
            >
              {value}
            </Flex>
          </Flex>

          <Flex mt="16px" fontSize="16px" textAlign="center">
            {name}
          </Flex>
          {description ? (
            <Text
              mt="16px"
              fontSize="12px"
              fontWeight="bold"
              textAlign="center"
              noOfLines={1}
            >
              {description}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </CardDefault>
  );
}

export default CardResultDash;
