import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import bgLogin from "assets/images/background-login.svg";
import recomendacaoFacial from "assets/images/facial-recomendacao.png";
import logo from "assets/images/logo-horizontal.png";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import CardDefault from "ui/components/Cards/CardDefault";
import InputFile from "ui/components/Input/InputFile";
import ModalCamera from "ui/components/ModalCamera";
import OutlineButton from "ui/components/Button/OutlineButton";
import useCadastrarFacialPortaria from "core/features/portaria/useCadastrarFacialPortaria";
import PrimaryButton from "ui/components/Button/PrimaryButton";
import { showSuccess } from "infra/helpers/alerta";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import InputCpfCnpj from "ui/components/Input/InputCpfCnpj";

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

function CadastrarFacialPortariaPage() {
  const route = useRouteMatch<{ portariaId: string }>();

  const { control, watch } = useForm<{
    nome: string;
    documento: string;
  }>({
    defaultValues: {
      nome: "",
      documento: "",
    },
    reValidateMode: "onBlur",
  });

  const form = watch();

  const [file, setFile] = useState<string | null>(null);
  const [modalCameraOpen, setModalCameraOpen] = useState(false);

  const { mutate, isLoading } = useCadastrarFacialPortaria(
    route?.params?.portariaId
  );

  const onSubmit = () => {
    if (!file || !form.nome || !form.documento) return;

    const [, imageString] = file.split(",");

    mutate(
      { base64Image: imageString, nome: form.nome, documento: form.documento },
      {
        onSuccess: () => {
          showSuccess("Facial cadastrada com sucesso!");
        },
      }
    );
  };

  if (!route.params.portariaId) {
    return (
      <Flex
        backgroundImage={bgLogin}
        h="100dvh"
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        <Container
          maxW="sm"
          alignItems="center"
          pt={4}
          display="flex"
          flexDir="column"
        >
          <Image w="100%" height="auto" src={logo} alt="Logo Octopus" mb={4} />

          <Text fontWeight="bold" fontSize={20} textAlign="center" color="red">
            Link inválido!
          </Text>
          <Text fontWeight="bold" fontSize={20} textAlign="center" color="red">
            Peça um novo para quem autorizou a portaria.
          </Text>
        </Container>
      </Flex>
    );
  }

  return (
    <Flex
      backgroundImage={bgLogin}
      minH="100dvh"
      h="fit-content"
      bgSize="cover"
      bgRepeat="no-repeat"
    >
      <Container
        w="container.lg"
        justifyContent="center"
        pt={4}
        minH="100dvh"
        display="flex"
        flexDir="row"
      >
        <CardDefault
          rounded={8}
          px={4}
          py={2}
          minH="90dvh"
          h="fit-content"
          w="100%"
          maxW="900px"
          alignItems="center"
          display="flex"
          flexDir="column"
          bg="rgba(255,255,255,0.5)"
          cursor="default"
        >
          <>
            <Image
              position="relative"
              height="60px"
              w="auto"
              src={logo}
              alt="Logo Octopus"
            />

            <Text color={colors.black700} my={4} textAlign="center">
              Completar cadastro de portaria
            </Text>

            <Box mb={2} w="100%">
              <Field
                name="nome"
                control={control}
                rules={{ required: true }}
                label="Nome"
              />
            </Box>
            <Box mb={2} w="100%">
              <Field
                name="documento"
                control={control}
                rules={{ required: true }}
                label="CPF/CNPJ"
                render={({ field, isInvalid }) => (
                  <InputCpfCnpj {...field} isInvalid={isInvalid} />
                )}
              />
            </Box>

            <Text color={colors.black700} my={1} textAlign="center">
              Cadastrar facial
            </Text>

            <Flex
              gap={2}
              my={2}
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
            >
              <InputFile
                showIcon={false}
                accept="image/*"
                showPreview={false}
                label="Buscar do dispositivo"
                value={null}
                onChange={async (f) => {
                  if (f) {
                    const base64 = await getBase64(f);
                    setFile(base64);
                  }
                }}
              />

              <OutlineButton onClick={() => setModalCameraOpen(true)}>
                Abrir câmera
              </OutlineButton>
            </Flex>

            {file ? (
              <>
                <Flex
                  flex={1}
                  gap={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    h="28dvh"
                    w="100%"
                    objectFit="contain"
                    src={file}
                    mb={2}
                  />
                </Flex>
                <PrimaryButton
                  onClick={onSubmit}
                  w="100%"
                  isLoading={isLoading}
                  disabled={!form.nome || !form.documento || isLoading}
                >
                  Salvar
                </PrimaryButton>
              </>
            ) : null}

            {modalCameraOpen ? (
              <ModalCamera
                visible={modalCameraOpen}
                onClose={() => setModalCameraOpen(false)}
                onSubmit={(uri) => setFile(uri)}
              />
            ) : null}

            {!file ? (
              <>
                <Text
                  color={colors.black600}
                  my={4}
                  textAlign="center"
                  fontSize={12}
                >
                  Recomendação para a foto
                </Text>

                <Image
                  w="100%"
                  height="auto"
                  src={recomendacaoFacial}
                  alt="Recomendação p/ Facial"
                  mb={4}
                />
              </>
            ) : null}
          </>
        </CardDefault>
      </Container>
    </Flex>
  );
}

export default CadastrarFacialPortariaPage;
