import { useMutation } from "react-query";
import axios from "infra/http";

function useConfirmarConvidadoPortaria(portariaId?: string) {
  return useMutation((form: { documento: string; nome: string }) =>
    axios.post<{ qrCode: string }>(
      `/v1/shared/portaria/${portariaId}/confirmar-convidado`,
      {
        ...form,
      }
    )
  );
}

export default useConfirmarConvidadoPortaria;
