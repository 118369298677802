import {
  Button as ButtonChakra,
  ButtonProps as ButtonChakraProps,
} from "@chakra-ui/react";

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { useRef } from "react";
import useHasPermission from "core/features/permissions/hooks/useHasPermission";

export interface ButtonProps extends ButtonChakraProps {
  permissions?: string | string[];
}

function Button(props: ButtonProps) {
  const initialFocusRef = useRef(null);

  const hasPermission = useHasPermission(props.permissions ?? []);

  if (props.permissions?.length && !hasPermission) {
    return (
      <Popover
        initialFocusRef={initialFocusRef}
        placement="bottom"
        closeOnBlur={false}
        trigger="hover"
      >
        <PopoverTrigger>
          <ButtonChakra
            rounded={8}
            px={4}
            py={4}
            fontSize={14}
            {...props}
            disabled
          />
        </PopoverTrigger>

        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            Você não possui permissão para acessar este recurso
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return <ButtonChakra rounded={8} px={4} py={4} fontSize={14} {...props} />;
}

export default Button;
