import { Flex } from "@chakra-ui/layout";
import { useMemo } from "react";
import { BoletosDto } from "core/features/boleto/typings";
import moment from "moment";
import { formatMoney } from "infra/helpers/number";
import TagPago from "ui/pages/App/Boletos/components/TagPago";
import TagBoleto from "ui/pages/App/Boletos/components/TagBoleto";
import TagPix from "ui/pages/App/Boletos/components/TagPix";

function useTableBoletoColumns({
  pixClickHandler,
  boletoClickHandler,
}: {
  pixClickHandler: (id: string) => any;
  boletoClickHandler: (referencia: string) => any;
}) {
  return useMemo(() => {
    return [
      {
        title: "Vencimento",
        width: "350px",
        render: (original: BoletosDto) =>
          moment(original.dataVencimento).format("DD/MM/YYYY"),
      },
      {
        title: "Descrição",
        render: (original: BoletosDto) => <Flex>{original.descricao}</Flex>,
      },
      {
        title: "Valor",
        width: "350px",
        render: (original: BoletosDto) => (
          <Flex>{formatMoney(original.valor)}</Flex>
        ),
      },
      {
        title: "Status",
        width: "350px",
        render: (original: BoletosDto) => (
          <TagPago
            vencimento={original.dataVencimento}
            pagamento={original.dataPagamento}
          />
        ),
      },
      {
        title: "Opções de pagamento",
        width: "300px",
        onClick: () => {},
        render: (original: BoletosDto) =>
          !original.dataPagamento ? (
            <Flex gap="3" mt={"-2px"}>
              <TagBoleto
                onClick={boletoClickHandler}
                referencia={original.arquivo?.referencia}
                disabled={!original.arquivo}
              />
              <TagPix
                onClick={pixClickHandler}
                id={original.id}
                disabled={!original.codigoPix}
              />
            </Flex>
          ) : null,
      },
    ];
  }, [boletoClickHandler, pixClickHandler]);
}

export default useTableBoletoColumns;
