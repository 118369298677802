import { useCallback, useEffect, useState } from "react";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import error from "core/resources/firebase/error";
import { showError, showSuccess } from "infra/helpers/alerta";
import history from "infra/helpers/history";

const auth = getAuth();

export default function useResetPassword() {
  const [isLoading, setIsLoading] = useState(false);

  const search = history.location.search?.substring(1);

  const params = new URLSearchParams(search);
  const oobCode = params.get("oobCode") || (params.get("oobcode") as string);

  useEffect(() => {
    (async () => {
      if (!oobCode) {
        return showError("Código de recuperação de senha inválido").then(() => {
          history.push("/auth/login");
        });
      } else {
        verifyPasswordResetCode(auth, oobCode).catch(() => {
          showError("Código de recuperação expirou").then(() =>
            history.push("/auth/login")
          );
        });
      }
    })();
  }, [oobCode]);

  const handleReset = useCallback(
    async (form) => {
      try {
        if (form.password !== form.confirm_password) {
          showError("As senhas estão diferentes");
          return;
        }

        await confirmPasswordReset(auth, oobCode, form.password);

        setIsLoading(true);

        showSuccess("Senha alterada com sucesso!").then(() =>
          history.push("/auth/login")
        );
      } catch (err) {
        showError(
          error[(err as any).code] || "Houve um problema ao alterar a senha"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [oobCode]
  );

  return {
    isLoading,
    handleReset,
  };
}
