import "sweetalert2/src/sweetalert2.scss";
import "antd/dist/antd.css"; 
import "assets/styles/index.scss";
import "../packages/sweetalert2/styles.scss";
import "react-photo-view/dist/react-photo-view.css";

import { init as initFirebase } from "infra/packages/firebase";
import { init as initMixpanel } from "infra/packages/mixpanel";
import { init as initSentry } from "../packages/@sentry";

initFirebase();
initSentry();
initMixpanel();
