import { VotacaoPerguntaDto } from "core/features/assembleia/typings";
import colors from "core/resources/theme/colors";
import moment from 'moment';

export function getColorForPerguntaItemSubtitle(
  pergunta: VotacaoPerguntaDto,
): string {
  const hasEnded = moment(pergunta.dataEncerramento).isBefore(moment());

  if (hasEnded) {
    return 'gray.600';
  }

  if ((!hasEnded && pergunta.respondida && !pergunta.mostraResultadoParcial)) {
    return colors.yellow;
  }

  if (pergunta.respondida) {
    return colors.green;
  }

  return 'blue.500';
}

export function getSubTitleForPerguntaItemCard(pergunta: VotacaoPerguntaDto): string {
  const hasEnded = moment(pergunta.dataEncerramento).isBefore(moment());

  if (hasEnded) {
    return 'Resultado disponível'
  }

  if (pergunta.mostraResultadoParcial && !hasEnded && pergunta.respondida) {
    return 'Veja o resultado parcial'
  }

  if (!hasEnded && pergunta.respondida) {
    return 'Aguardando resultado'
  }

  return 'Em aberto'
}