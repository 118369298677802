import colors from "core/resources/theme/colors";
import Button, { ButtonProps } from "./";

function PrimaryButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      rounded={8}
      px={4}
      py={4}
      bgColor={colors.primary}
      color="white"
      display="flex"
      fontWeight="normal"
      gridGap={2}
      fontSize={14}
      _hover={{
        bg: rest.bgColor || colors.primary,
        opacity: rest.disabled ? 0.6 : 0.8,
      }}
      _active={{
        opacity: 0.5,
      }}
      _disabled={{
        opacity: 0.3,
      }}
      _focus={{
        outline: "none",
      }}
      cursor={rest.disabled ? "not-allowed" : "pointer"}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
