import { Switch } from "react-router-dom";
import AuthRoute from "ui/components/AuthRoute";
import Detalhes from "./Detalhes";
import Listagem from "./Listagem";

function AssembleiaPages() {
  return (
    <Switch>
      <AuthRoute exact path="/assembleias" component={Listagem} />
      <AuthRoute path="/assembleias/:id" component={Detalhes} />
    </Switch>
  );
}

export default AssembleiaPages;
