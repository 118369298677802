import { useCallback, useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

import { showError } from "infra/helpers/alerta";
import error from "core/resources/firebase/error";

const auth = getAuth();

export default function useRegister() {
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = useCallback(async ({ nome, username, password }) => {
    try {
      setIsLoading(true);

      const { user } = await createUserWithEmailAndPassword(
        auth,
        username,
        password
      );

      await updateProfile(user, {
        displayName: nome,
      });

      await signInWithEmailAndPassword(auth, username, password);

      window.location.reload();
    } catch (err) {
      showError(error[(err as any).code]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    handleRegister,
    isLoading,
  };
}
