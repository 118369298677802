import { showError } from 'infra/helpers/alerta';
import history from 'infra/helpers/history'
import { useLocation } from 'react-router';

const mapModeToPage: Record<string, string> = {
  resetPassword: '/auth/reset',
  resetpassword: '/auth/reset',
}

function useFirebaseActionsHandler() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const mode = params.get('mode') as string;
  const oobCode = (params.get("oobcode") || params.get('oobCode')) as string;
  const continueUrl = params.get('continueurl') || params.get('continueUrl');

  if (continueUrl) {
    return window.location.replace(`${continueUrl}?mode=${mode}&oobCode=${oobCode}`)
  }

  const pageToRedirect = mapModeToPage[mode];

  if (pageToRedirect) {
    return history.push(`${pageToRedirect}?oobCode=${oobCode}`)
  }

  return showError().then(() => history.push('/'));

}

export default useFirebaseActionsHandler
