import { useForm } from "react-hook-form";
import { Row, Col } from "antd";
import { Container, CardLogin } from "../Login/styles";
import history from "infra/helpers/history";
import useRecover from "core/features/auth/reset/useRecover";
import { Button } from "@chakra-ui/button";
import { Text } from "@chakra-ui/layout";
import Field from "ui/components/Input/Field";
import { isValidEmail } from "@brazilian-utils/brazilian-utils";

function RecoverPage() {
  const { control, handleSubmit } = useForm<{
    email: any;
  }>({
    reValidateMode: "onChange",
  });

  const { isLoading, handleRecuperar } = useRecover();

  return (
    <Container>
      <CardLogin>
        <form onSubmit={(ev: any) => handleSubmit(handleRecuperar)(ev)}>
          <Text fontSize="2xl">Recuperar senha</Text>
          <br />

          <Row gutter={16}>
            <Col xs={24}>
              <Field
                control={control}
                label="Email"
                name="email"
                rules={{
                  required: {
                    message: "Informe o e-mail",
                    value: true,
                  },
                  validate: (v) => isValidEmail(v) || "Email inválido",
                }}
              />
            </Col>
          </Row>

          <br />

          <div>
            <Row gutter={16}>
              <Col xs={24}>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  colorScheme="blue"
                  isFullWidth
                >
                  Recuperar senha
                </Button>
              </Col>
            </Row>
          </div>

          <br />
          <br />
          <br />

          <div>
            <Row gutter={16}>
              <Col sm={24}>
                <Button onClick={() => history.push("/auth/login")} isFullWidth>
                  Voltar
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </CardLogin>
    </Container>
  );
}

export default RecoverPage;
