import { useMutation } from "react-query";
import axios from "infra/http";

function useCadastrarFacialPortaria(portariaId?: string) {
  return useMutation(
    (form: { base64Image: string; nome: string; documento: string }) => {
      return axios.post<{ success: boolean }>(
        `/v1/shared/portaria/${portariaId}/completar-cadastro-facial`,
        form
      );
    }
  );
}

export default useCadastrarFacialPortaria;
