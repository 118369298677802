import { AxiosError } from "axios";
import { showError } from "./alerta";
import error from "core/resources/firebase/error";

interface APIError {
  error: string;
  statusCode: number;
  message: string[];
}

export function showFeedbackForError(err: any) {
  if (err.response) {
    const _error = err as AxiosError;
    const data = _error.response?.data as APIError

    if (data && Array.isArray(data.message) && data.message.length) {
      showError(data?.message[0])

    } else if (data && typeof data.message === 'string') {
      showError(data.message)
      
    } else {
      showError('Ocorreu um erro desconhecido ao realizar essa operação')
    }
  } else if (err.code) {
    showError(error[err.code]);
  }
}