import { Flex, FlexProps, Icon } from "@chakra-ui/react";
import React from "react";
import { CgAdd, CgCloseO } from "react-icons/cg";

interface AddRemoveLineButtonProps {
  onAdd?: () => any;
  onRemove?: () => any;
  showRemove?: boolean;
  showAdd?: boolean;
  containerProps?: FlexProps;
  size?: number;
}
function AddRemoveLineButton({
  onAdd,
  onRemove,
  showRemove = true,
  showAdd = true,
  containerProps,
  size = 8,
}: AddRemoveLineButtonProps) {
  return (
    <Flex alignItems="center" h="100%" pt={4} {...containerProps}>
      {showAdd ? (
        <Icon
          as={CgAdd}
          w={size}
          h={size}
          color="#4F52FF"
          mr={2}
          onClick={onAdd}
          cursor="pointer"
        />
      ) : null}
      {showRemove ? (
        <Icon
          as={CgCloseO}
          w={size}
          h={size}
          color="#EE5322"
          onClick={onRemove}
          cursor="pointer"
        />
      ) : null}
    </Flex>
  );
}

export default AddRemoveLineButton;
