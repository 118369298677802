import { useCallback } from "react";
import { getAuth } from "firebase/auth";
import { useCurrentAuth } from "../auth.store";
import { useCurrentTenant } from "../tenant.store";
import { trackEvent } from "infra/helpers/event";
import { useCurrentUnidade } from "../unidade.store";
import { useHistory } from "react-router-dom";

const auth = getAuth();

export default function useLogout() {
  const history = useHistory();
  const { reset: resetAuth } = useCurrentAuth();
  const { reset: resetTenant } = useCurrentTenant();
  const { reset: resetUnidade } = useCurrentUnidade();

  const handleLogout = useCallback(async () => {
    const email = auth.currentUser?.email;
    const provider = auth.currentUser?.providerData?.[0]?.providerId;

    await auth.signOut();

    resetAuth();
    resetTenant();
    resetUnidade();

    trackEvent("logout.sucesso", {
      email,
      provider,
    });

    if (!history.location.pathname.includes("auth")) {
      history.push("/auth/login");
    }
  }, [history, resetAuth, resetTenant, resetUnidade]);

  return {
    handleLogout,
  };
}
