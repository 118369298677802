import { Flex, Tag } from "@chakra-ui/react";
import { AiOutlineBarcode } from "react-icons/ai";
import { Tooltip } from "antd";

function TagBoleto({
  onClick,
  referencia,
  disabled,
}: {
  onClick: (id: string) => any;
  referencia?: string;
  disabled?: boolean;
}) {
  return (
    <Tooltip
      title={disabled ? "Boleto não disponível" : "Pague com Boleto"}
      placement="bottom"
    >
      <Tag
        size="md"
        variant="outline"
        colorScheme="blue"
        onClick={() => (disabled || !referencia ? null : onClick(referencia))}
        cursor={disabled ? "not-allowed" : "pointer"}
        opacity={disabled ? 0.7 : 1}
      >
        <Flex gap={2}>
          <AiOutlineBarcode fontSize="18px" />
          Boleto
        </Flex>
      </Tag>
    </Tooltip>
  );
}

export default TagBoleto;
