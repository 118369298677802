import Lottie from "lottie-react-web";
import React, { useEffect } from "react";
import lottieLoading from "assets/lotties/92541-loading.json";
import { Box, Flex } from "@chakra-ui/react";
import create from "zustand";
import { useWindowSize } from "infra/helpers/window";

export const GlobalLoadingContext = React.createContext({
  isLoading: false,
});

export const useLoadingStore = create<{
  isLoading: boolean;
  update: (v: boolean) => any;
}>((set) => ({
  isLoading: false,
  update: (isLoading: boolean) => set(() => ({ isLoading })),
}));

export const useLoading = (state: boolean) => {
  const { update } = useLoadingStore();

  useEffect(() => {
    update(state);
  }, [state, update]);
};

function GlobalLoading() {
  const { isLoading } = useLoadingStore();

  const windowSize = useWindowSize();

  const isMobile = windowSize.width <= 992;

  if (!isLoading) {
    return null;
  }

  return (
    <Flex
      position="fixed"
      left={0}
      right={0}
      top={0}
      bottom={0}
      zIndex={99999}
      bg="rgba(0,0,0,.4)"
      justifyContent="center"
      alignItems="center"
    >
      <Box bg="white" rounded={"lg"} px="48px">
        <Lottie
          width={isMobile ? "100px" : "200px"}
          options={{
            animationData: lottieLoading,
          }}
        />
      </Box>
    </Flex>
  );
}

export default GlobalLoading;
