import { useForm } from "react-hook-form";
import { Row, Col } from "antd";

import useResetPassword from "core/features/auth/logout/useResetPassword";
import { Container, CardLogin } from "../Login/styles";
import { Button } from "@chakra-ui/button";
import { Text } from "@chakra-ui/layout";
import Field from "ui/components/Input/Field";

function ResetPage() {
  const { control, handleSubmit } = useForm<{
    password: any;
    confirm_password: any;
  }>({
    reValidateMode: "onChange",
  });

  const { isLoading, handleReset } = useResetPassword();

  return (
    <Container>
      <CardLogin>
        <form onSubmit={(ev) => handleSubmit(handleReset)(ev)}>
          <Text fontSize="2xl">Recuperar senha</Text>

          <br />
          <Row gutter={16}>
            <Col xs={24}>
              <Field
                label="Senha"
                control={control}
                name="password"
                inputProps={{
                  type: "password",
                }}
                rules={{
                  required: {
                    message: "Informe a senha",
                    value: true,
                  },
                }}
              />
            </Col>
          </Row>

          <br />

          <Row gutter={16}>
            <Col xs={24}>
              <Field
                label="Confirme a senha"
                control={control}
                name="confirm_password"
                inputProps={{
                  type: "password",
                }}
                rules={{
                  required: {
                    message: "Informe a senha de confirmação",
                    value: true,
                  },
                }}
              />
            </Col>
          </Row>
          <br />

          <div>
            <Row gutter={16}>
              <Col sm={24}>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  isFullWidth
                >
                  Atualizar senha
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </CardLogin>
    </Container>
  );
}

export default ResetPage;
