import colors from "core/resources/theme/colors";
import styled from "styled-components";

export const FieldContainer = styled.div<any>`
  width: 100%;
  .chakra-input {
    font-size: ${(props) => props.fontSize}px !important;
  }
  .chakra-input:hover,
  .chakra-input:focus {
    border-color: ${(props) =>
      props.isInvalid ? colors.red : colors.primary} !important;
    box-shadow: 0 0 0 1px
      ${(props) => (props.isInvalid ? colors.red : colors.primary)} !important;
    transition: all 0.2s;
  }
`;
