import { Route, Switch } from "react-router-dom";

import BoletoPage from "./Boleto";

function Pdf() {
  return (
    <Switch>
      <Route exact path="/pdf/boleto/:id" component={BoletoPage} />
    </Switch>
  );
}

export default Pdf;
