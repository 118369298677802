import { Box, Text, Flex, Center, Spacer } from "@chakra-ui/react";
import { VotacaoPerguntaDto } from "core/features/assembleia/typings";
import { FiChevronRight } from "react-icons/fi";
import { getSubTitleForPerguntaItemCard } from "ui/pages/App/Assembleia/Detalhes/components/PerguntasSelect/helper";
import { getColorForPerguntaItemSubtitle } from "./helper";
import moment from "moment";
import { showError } from "infra/helpers/alerta";

interface PerguntasSelectProps {
  pergunta: VotacaoPerguntaDto;
  ordem: number;
  onClick: (id: string) => any;
}

function PerguntasSelect({ pergunta, ordem, onClick }: PerguntasSelectProps) {
  const hasEnded = moment(pergunta.dataEncerramento).isBefore(moment());

  return (
    <Box
      borderRadius="10px"
      boxShadow="10px 10px 10px #eff4f5"
      mb="20px"
      px="16px"
      py="10px"
      border="1px solid #eff4f5"
      cursor="pointer"
      onClick={() => {
        if (
          !hasEnded &&
          !pergunta.mostraResultadoParcial &&
          pergunta.respondida
        ) {
          showError("Resultado parcial indisponível para essa pergunta");
        } else {
          onClick(pergunta.id);
        }
      }}
    >
      <Flex flexDir="row">
        <Box>
          <Flex>
            <Text fontWeight="bold" noOfLines={1}>{`${
              ordem ? ordem + 1 : 1
            } - ${pergunta.titulo}`}</Text>
          </Flex>
          <Flex>
            <Text
              fontSize="10px"
              fontWeight="bold"
              color={getColorForPerguntaItemSubtitle(pergunta)}
            >
              {getSubTitleForPerguntaItemCard(pergunta)}
            </Text>
          </Flex>
        </Box>
        <Spacer />
        <Center>
          <FiChevronRight fontSize="20px" />
        </Center>
      </Flex>
    </Box>
  );
}

export default PerguntasSelect;
