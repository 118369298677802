import { Switch } from "react-router-dom";
import AuthRoute from "ui/components/AuthRoute";
import HomePage from "./Home";
import AssembleiaPages from "./Assembleia";
import Layout from "ui/components/Layout";
import BoletosPage from "./Boletos";

function AppPages() {
  return (
    <Layout>
      <Switch>
        <AuthRoute path="/" component={HomePage} exact />
        <AuthRoute path="/assembleias" component={AssembleiaPages} />
        <AuthRoute path="/boletos" component={BoletosPage} />
      </Switch>
    </Layout>
  );
}

export default AppPages;
