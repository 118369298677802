import mixpanel from "mixpanel-browser";
import { useCurrentAuth } from "core/features/auth/auth.store";
import { useCurrentTenant } from "core/features/auth/tenant.store";
import { useCurrentUnidade } from "core/features/auth/unidade.store";

export function trackEvent(eventName: string, props?: any) {
  if (!props) {
    props = {};
  }

  const { tenant } = useCurrentTenant.getState();
  const { auth } = useCurrentAuth.getState();
  const { unidade } = useCurrentUnidade.getState();

  props.createdAt = new Date().toISOString();
  props.userId = auth?.id ?? null;
  props.tenantId = tenant?.id ?? null;
  props.unidadeId = unidade?.id ?? null;

  mixpanel.track(eventName, props);
}
