import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import config from "infra/config";

let app: FirebaseApp = getApps()[0];

export function init() {
  if (!getApps().length) {
    app = initializeApp(config.firebase);
  }
}

export default app;
