import { useBreadcrumb } from "features/breadcrumbs";
import TableBoletos from "./components/TableBoletos";
import ModalDetalhes from "./components/ModalDetalhes";
import { useState } from "react";
import { Container, Text } from "@chakra-ui/layout";

function BoletosPage() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idBoleto, setIdBoleto] = useState("");

  useBreadcrumb([
    {
      name: "Página Inicial",
      url: "/",
    },
    {
      name: "Boletos",
      url: "/boletos",
    },
  ]);

  function clickPixHandler(id: string) {
    setIdBoleto(id);
    setIsOpenModal(true);
  }

  return (
    <Container maxW="100%">
      <Text fontSize="20px" mb={8}>
        Boletos da Unidade
      </Text>

      <TableBoletos clickPix={clickPixHandler} />

      <ModalDetalhes
        isOpenModal={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        id={idBoleto}
      />
    </Container>
  );
}

export default BoletosPage;
