import { Flex, Text, Box, Spacer, Skeleton } from "@chakra-ui/react";
import useVotacaoStats from "core/features/assembleia/hooks/useVotacaoStats";
import { VotacaoPerguntaDto } from "core/features/assembleia/typings";
import colors from "core/resources/theme/colors";
import { useRouteMatch } from "react-router-dom";
import Toolbar from "ui/components/Toolbar";
import RefetchButton from "ui/pages/App/Assembleia/Detalhes/components/RefetchButton";
import SidebarContainer from "../SitebarContainer";
import moment from "moment";

function ResultadoBox({
  pergunta,
  onReturn,
}: {
  pergunta: VotacaoPerguntaDto;
  onReturn: () => void;
}) {
  const route = useRouteMatch<any>();
  const { data, isFetching, refetch } = useVotacaoStats(
    route.params.id,
    pergunta.id
  );

  const hasEnded = moment(data?.dataEncerramento).isBefore(moment());

  return (
    <Box>
      <Toolbar
        title="Resultado"
        onBack={onReturn}
        rightElement={
          <Box ml="auto">
            <RefetchButton refetch={refetch} />
          </Box>
        }
      />

      <SidebarContainer>
        {/* <Flex gap="10px">
          <CardResultados
            bg={colors.blue100}
            isLoading={isFetching}
            name="Total de votos"
            icon={<ImStatsBars fontSize="24px" color={colors.purple} />}
            complete={false}
            value={`${data?.total || "--"}`}
          />
          <CardResultados
            bg={colors.blue100}
            isLoading={isFetching}
            name="Nº de moradores"
            icon={<FaUsers fontSize="24px" color={colors.purple} />}
            complete={false}
            value={`${data?.total || "--"}`}
          />
        </Flex> */}

        {isFetching ? (
          <>
            <Skeleton w="100%" h="70px" mb={5} />
            <Skeleton w="100%" h="70px" mb={5} />
            <Skeleton w="100%" h="70px" mb={5} />
          </>
        ) : null}

        {!isFetching && data?.opcoes.length
          ? data.opcoes.map((opcao: any, index: any) => (
              <ResultBar
                color={colors.black50}
                label={opcao.titulo}
                total={data.total}
                votos={opcao.votos}
                key={index}
              />
            ))
          : null}

        {!isFetching && !hasEnded ? (
          <Text>Esta votação ainda não foi finalizada</Text>
        ) : null}
      </SidebarContainer>
    </Box>
  );
}

function ResultBar({
  total,
  votos,
  label,
  color,
}: {
  total: number;
  votos: number;
  label: string;
  color: string;
}) {
  return (
    <Box mb={4}>
      <Flex mb={1}>
        <Text fontWeight="semibold" fontSize={16} rounded={6}>
          {label}
        </Text>
        <Spacer />
      </Flex>
      <Flex
        rounded={6}
        position="relative"
        justifyContent="flex-start"
        alignItems="center"
        borderWidth={2}
        borderColor={colors.black50}
        padding="8px"
        bg={`linear-gradient(to right, ${color} 0 ${
          (votos / total) * 100
        }%, white ${(votos / total) * 100}% 100%)`}
      >
        <Text fontSize={14} rounded={6} p={1}>
          {votos ?? 0} {votos === 1 ? "voto" : "votos"}
        </Text>
      </Flex>
    </Box>
  );
}

export default ResultadoBox;
