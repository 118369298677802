import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

function CardDefault({
  children,
  ...props
}: {
  children:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactChildren
    | React.ReactChildren[]
    | JSX.Element
    | JSX.Element[];
} & BoxProps) {
  return (
    <Box
      cursor="pointer"
      bg="white"
      rounded="16px"
      shadow="md"
      p="16px"
      {...props}
    >
      {children}
    </Box>
  );
}

export default CardDefault;
