import { useForm } from "react-hook-form";

import useLogin from "core/features/auth/login/useLogin";
import { Container, CardLogin } from "./styles";
import colors from "core/resources/theme/colors";
import history from "infra/helpers/history";
import { Button } from "@chakra-ui/button";
import { Flex, Text, Box } from "@chakra-ui/layout";
import Field from "ui/components/Input/Field";
import mascoteImg from "assets/images/Polvo-mascote.png";
import { Image } from "@chakra-ui/image";
import { isValidEmail } from "@brazilian-utils/brazilian-utils";
import { MdFacebook } from "react-icons/md";
import { FaGooglePlus } from "react-icons/fa";

function LoginPage() {
  const { control, handleSubmit } = useForm<{
    username: any;
    password: any;
  }>({
    reValidateMode: "onChange",
  });

  const {
    isLoading,
    handleLoginEmail,
    handleLoginFacebook,
    handleLoginGoogle,
  } = useLogin();

  return (
    <Container>
      <CardLogin>
        <Flex
          justifyContent="center"
          alignItems="center"
          pos="absolute"
          left="0"
          right="0"
          top="-120px"
        >
          <Image w="165px" src={mascoteImg} />
        </Flex>

        <Text fontWeight="bold" textAlign="center" fontSize="2xl">
          Toppus
        </Text>

        <form onSubmit={(ev) => handleSubmit(handleLoginEmail)(ev)}>
          <br />
          <Box w="100%">
            <Field
              label="Email"
              control={control}
              name="username"
              rules={{
                required: {
                  message: "Informe o e-mail",
                  value: true,
                },
                validate: (v) => isValidEmail(v) || "Email inválido",
              }}
            />
          </Box>

          <br />

          <Box w="100%">
            <Field
              label="Senha"
              inputProps={{
                type: "password",
              }}
              control={control}
              name="password"
              rules={{
                required: {
                  message: "Informe a senha",
                  value: true,
                },
              }}
            />
          </Box>

          <div
            style={{
              marginTop: 8,
              fontWeight: "bold",
              cursor: "pointer",
              color: colors.primary,
            }}
            onClick={() => history.push("/auth/recover")}
          >
            <Text>Esqueci a senha</Text>
          </div>
          <br />

          <div>
            <Box style={{ width: "100%" }}>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                colorScheme="blue"
                isFullWidth
              >
                Entrar
              </Button>
            </Box>

            <div
              style={{
                marginTop: 16,
                marginBottom: 16,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              ou
            </div>

            <Box style={{ marginTop: 16, width: "100%" }}>
              <Button isFullWidth onClick={() => handleLoginFacebook()}>
                <MdFacebook style={{ color: colors.blue, marginRight: 16 }} />
                <span style={{ color: colors.blue }}>Facebook</span>
              </Button>
            </Box>

            <Box style={{ marginTop: 16, width: "100%" }}>
              <Button isFullWidth onClick={() => handleLoginGoogle()}>
                <FaGooglePlus style={{ color: colors.red, marginRight: 16 }} />
                <span style={{ color: colors.red }}>Google Plus</span>
              </Button>
            </Box>
          </div>
        </form>
      </CardLogin>
    </Container>
  );
}

export default LoginPage;
