import { useCurrentAuth } from "core/features/auth/auth.store";
import { showError } from "infra/helpers/alerta";

export function checkPermission(
  permission: string | string[],
  message?: string | true
) {
  const { auth, isLogged } = useCurrentAuth.getState();

  if (!isLogged) {
    return false;
  }

  if (Array.isArray(permission)) {
    const hasPermission = !!auth.permissoes?.some((p: any) =>
      permission.includes(p)
    );

    if (!hasPermission) {
      if (typeof message === "string") {
        showError(message);
      } else if (message) {
        showError("Sem permissão para realizar esta operação!");
      }

      return false;
    }

    return true;
  }

  const hasPermission = !!auth.permissoes?.includes(permission);

  if (!hasPermission) {
    if (typeof message === "string") {
      showError(message);
    } else if (message) {
      showError("Sem permissão para realizar esta operação!");
    }

    return false;
  }

  return true;
}

export function checkOrPermission(permission: string[]) {
  const { auth, isLogged } = useCurrentAuth.getState();

  if (!isLogged) {
    return false;
  }

  return !!auth.permissoes?.some((p: any) => permission.includes(p));
}
