import { FieldContainer } from "./styles";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

function InputCpfCnpj({ value, onChange, ...props }: any) {
  return (
    <FieldContainer>
      <CpfCnpj
        value={value}
        className="chakra-input"
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
        {...props}
      />
    </FieldContainer>
  );
}

export default InputCpfCnpj;
