import {
  Container,
  Text,
  Box,
  SimpleGrid,
  Skeleton,
  Image,
  Flex,
  Tag,
} from "@chakra-ui/react";
import useAssembleias from "features/assembleia/hooks/useAssembleias";
import { useBreadcrumb } from "features/breadcrumbs";
import { useHistory } from "react-router-dom";
import CardDefault from "components/Cards/CardDefault";
import iconSvg from "assets/images/icons/icon.svg";
import moment from "moment";
import { VotacaoDto } from "features/assembleia/typings";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { showError } from "infra/helpers/alerta";
import { useCurrentAuth } from "features/auth/auth.store";

function AssembleiaListagem() {
  const { data, isFetching } = useAssembleias();
  const { auth } = useCurrentAuth();
  const history = useHistory();

  useBreadcrumb([
    {
      name: "Página Inicial",
      url: "/",
    },
    {
      name: "Votações",
    },
  ]);

  const isAberto = (item: VotacaoDto) =>
    moment(item.dataInicio).isBefore(moment());

  const isFinalizado = (item: VotacaoDto) =>
    moment(item.dataFim).isBefore(moment());

  const getText = (item: VotacaoDto) => {
    if (isAberto(item) && item.dataFim && isFinalizado(item)) {
      return `Encerrou em ${moment(item.dataFim).format("DD/MM/YYYY")}`;
    }

    if (isAberto(item)) {
      return `Em aberto desde ${moment(item.dataInicio).format(
        "DD/MM/YYYY [ás] HH:mm"
      )}`;
    }

    return `Abre em ${moment(item.dataInicio).format("DD/MM/YYYY [ás] HH:mm")}`;
  };

  const getColor = (item: VotacaoDto) => {
    if (isFinalizado(item)) {
      return "gray.500";
    }

    if (isAberto(item)) {
      return "green.500";
    }

    return "yellow.500";
  };

  return (
    <Container maxW="100%">
      <Text fontSize="20px">Votações</Text>
      <Box mt="32px">
        <SimpleGrid gap={"16px"}>
          {isFetching ? (
            <>
              <Skeleton rounded="16px" w="100%" h="120px" />
              <Skeleton rounded="16px" w="100%" h="120px" />
              <Skeleton rounded="16px" w="100%" h="120px" />
            </>
          ) : null}

          {data?.items.map((item, index) => (
            <CardDefault
              shadow={"sm"}
              onClick={() => {
                if (auth.podeParticiparVotacao) {
                  history.push(`/assembleias/${item.id}`);
                } else {
                  showError(
                    "Seu usuário não tem permissão para participar dessa assembleia."
                  );
                }
              }}
              key={index}
            >
              <Flex>
                <Flex pr="16px" flexDir={"column"}>
                  <Image w="20px" src={iconSvg} />
                </Flex>
                <Flex flex={1} flexDir="column">
                  <Box fontSize="16px">{item.nome}</Box>
                  <Box
                    mt="6px"
                    fontSize="12px"
                    fontWeight="bold"
                    color={getColor(item)}
                  >
                    {getText(item)}
                  </Box>
                </Flex>
                <Flex flexDir={"column"}>
                  {item.webinarAovivo ? (
                    <Tag
                      gap="4px"
                      rounded="full"
                      variant="solid"
                      colorScheme="red"
                    >
                      <MdOutlineOndemandVideo />
                      AO VIVO
                    </Tag>
                  ) : null}

                  {!item.webinarAovivo && item.webinarId ? (
                    <Tag
                      gap="4px"
                      rounded="full"
                      variant="outline"
                      colorScheme="blue"
                    >
                      <MdOutlineOndemandVideo />
                      Com video conferência
                    </Tag>
                  ) : null}
                </Flex>
              </Flex>
            </CardDefault>
          ))}
        </SimpleGrid>
      </Box>
    </Container>
  );
}

export default AssembleiaListagem;
