import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { useEffect, useState } from "react";
import { InputContainer } from "./styles";

function InputMoney({
  value,
  onChange,
  isInvalid,
  maxValue = Number.MAX_SAFE_INTEGER,
  minValue = 0,
  backgroundColor,
  ...props
}: {
  value: any;
  isInvalid?: boolean;
  onChange: any;
  maxValue?: number;
  minValue?: number;
  backgroundColor?: string;
} & CurrencyInputProps) {
  const [localValue, setLocalValue] = useState<string | undefined>(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (value < minValue || value > maxValue) {
      const nextValue = Math.min(Math.max(value, minValue), maxValue);

      onChange(nextValue);
      setLocalValue(`${nextValue.toFixed(2)}`);
    }
  }, [maxValue, minValue, onChange, value]);

  return (
    <InputContainer
      isInvalid={isInvalid}
      backgroundColor={backgroundColor}
      disabled={props.disabled}
    >
      <CurrencyInput
        value={localValue}
        groupSeparator="."
        decimalSeparator=","
        // intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        decimalsLimit={2}
        decimalScale={2}
        className="chakra-input"
        placeholder="0,00"
        style={{
          textAlign: "right",
        }}
        {...props}
        onValueChange={(c, name, values) => {
          if (
            values?.float &&
            values.float >= minValue &&
            values?.float <= maxValue
          ) {
            setLocalValue(c);
            onChange(values?.float);
          } else if (!values?.float) {
            setLocalValue("");
            onChange(0);
          }
        }}
      />
    </InputContainer>
  );
}

export default InputMoney;
