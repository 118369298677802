import { Container, Text, Box, Skeleton, Flex, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useAssembleia from "features/assembleia/hooks/useAssembleia";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Col, Row } from "antd";
import VotacaoTab from "./components/VotacaoTab";
import { MdHowToVote, MdPlayCircle } from "react-icons/md";

import { useBreadcrumb } from "features/breadcrumbs";
import { MeetingContainer } from "./styles";
import CardResultDash from "./components/CardResultDash";
import colors from "core/resources/theme/colors";
import { RiQuestionnaireLine } from "react-icons/ri";
import useCheckin from "features/assembleia/hooks/useCheckin";
import { useLoading } from "components/GlobalLoading";
import { showErrorAxios } from "infra/helpers/alerta";
import { useCurrentAuth } from "core/features/auth/auth.store";

function AssembleiaDetalhes() {
  const route = useRouteMatch<any>();
  const { auth } = useCurrentAuth();

  const history = useHistory();

  const {
    data,
    isFetching,
    isLoading: isLoadingAssembleia,
    refetch,
  } = useAssembleia(route.params.id);

  const {
    mutate: checkin,
    isLoading: isLoadingCheckin,
    isError,
  } = useCheckin();

  useEffect(() => {
    if (auth.podeVotar) {
      checkin(route.params.id, {
        onError: (err) => {
          showErrorAxios(err).then(() => {
            history.push(`/assembleias`);
          });
        },
      });
    }
  }, [route.params.id, checkin, history, auth.podeVotar]);

  useLoading(isLoadingCheckin);

  const [, setStatsDataId] = useState<string | undefined>(undefined);

  useBreadcrumb([
    {
      name: "Página Inicial",
      url: "/",
    },
    {
      name: "Votações",
      url: "/assembleias",
    },
    {
      name: data?.id ? data.nome : `Votação`,
    },
  ]);

  if (isError) {
    return null;
  }

  return (
    <Container maxW="100%">
      <Text fontSize="20px" mb="28px">
        {data?.nome ?? "Votação"}
      </Text>

      <Row gutter={16}>
        <Col sm={6}>
          <VotacaoTab
            onRefresh={() => {
              refetch();
            }}
            assembleia={data}
            changeQuestion={(id) => {
              setStatsDataId(id);
            }}
            isFetching={isFetching}
          />
        </Col>
        <Col sm={18}>
          <Box>
            <Row gutter={16}>
              <Col sm={7}>
                <CardResultDash
                  bg={colors.blue100}
                  name="Peso do meu voto"
                  icon={<MdHowToVote fontSize="24px" color={colors.blue} />}
                  complete={false}
                  value={data?.peso ?? "--"}
                  isLoading={isFetching}
                />
              </Col>
              <Col sm={7}>
                <CardResultDash
                  bg={colors.purple100}
                  name="Perguntas"
                  icon={
                    <RiQuestionnaireLine
                      fontSize="24px"
                      color={colors.purple}
                    />
                  }
                  complete={false}
                  value={data?.perguntas.length ?? "--"}
                  isLoading={isFetching}
                />
              </Col>
            </Row>
          </Box>
          <Box mt="32px">
            {isLoadingAssembleia ? (
              <Skeleton rounded="16px" w="100%" h="450px" />
            ) : null}
            {!isLoadingAssembleia && data?.webinarJoinUrl ? (
              <Flex
                flex={1}
                justifyContent="center"
                alignItems={"center"}
                rounded="16px"
                w="100%"
                h="450px"
              >
                <Button
                  colorScheme="blue"
                  gap="8px"
                  as="a"
                  size="lg"
                  target="_blank"
                  href={data.webinarJoinUrl}
                >
                  <MdPlayCircle /> Iniciar Video
                </Button>
              </Flex>
            ) : null}

            <MeetingContainer>
              <Box id="meetingSDKElement"></Box>
            </MeetingContainer>
          </Box>
        </Col>
      </Row>
    </Container>
  );
}

export default AssembleiaDetalhes;
