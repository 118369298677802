import React from "react";
import { trackEvent } from "infra/helpers/event";
import { useEffect } from "react";
import history from "infra/helpers/history";

import mixpanel from "mixpanel-browser";
import { MixpanelProvider as MixPanelProviderFC } from "react-mixpanel";
import config from "infra/config";
import { Me } from "core/features/auth/typings/user";

export function init() {
  mixpanel.init(config.mixpanel.key, { debug: config.isDev });
}

export function registerMixpanel(data: Partial<Me>) {
  mixpanel.register({
    id: data.id,
    nome: data.nome,
    email: data.email,
  });
}

function MixPanelProvider({ children }: { children: React.ReactChild }) {
  useEffect(() => {
    history.listen((listener) => {
      window.scrollTo({ top: 0 });

      trackEvent(`trocaTela.${listener.key}`, {
        pathname: listener.pathname,
        key: listener.key,
        state: listener.state,
      });
    });
  }, []);

  return (
    <MixPanelProviderFC mixpanel={mixpanel}>{children}</MixPanelProviderFC>
  );
}

export default MixPanelProvider;
