import { useEffect, useCallback, useState, useRef } from "react";

import Lottie from "lottie-react-web";
import { Flex } from "@chakra-ui/layout";

import http from "infra/http";
import { useCurrentAuth } from "./auth.store";
import lottieJson from "assets/lotties/splash.json";
import { showError } from "infra/helpers/alerta";
import useLogout from "core/features/auth/logout/useLogout";
import { useAuthState } from "react-firebase-hooks/auth";
import app from "infra/packages/firebase";
import { getAuth } from "firebase/auth";
import { registerMixpanel } from "infra/packages/mixpanel";
import { Me } from "./typings/user";
import { useCurrentUnidade } from "./unidade.store";
import { useHistory, useLocation } from "react-router-dom";

function AuthProvider({ children }: any) {
  const initialize = useRef(false);

  const [isLoading, setIsLoading] = useState(true);
  const { update: updateAuth, isLogged } = useCurrentAuth();

  const { search } = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(search);
  const redirectUrl = params.get("redirectUrl") as string;

  const { handleLogout } = useLogout();
  const [, loadingAuth] = useAuthState(getAuth(app));

  const init = useCallback(async () => {
    console.log("deu init");

    initialize.current = true;

    try {
      if (!isLogged) {
        setIsLoading(false);
        handleLogout();
        return;
      }

      const { data } = await http.get<Me>("/mobile/v1/auth/me");

      if (!data.unidades.length) {
        showError("Nenhuma unidade vinculada!").then(() => {
          handleLogout();
        });

        return;
      }
      updateAuth(data);

      const { unidade, selecionar } = useCurrentUnidade.getState();

      if (!unidade.id) {
        selecionar(data.unidades[0]);
      }

      registerMixpanel(data);

      const url = redirectUrl ? `/${redirectUrl}`.replace("//", "/") : "/";

      if (window.location.pathname.includes("auth/") || redirectUrl) {
        history.replace(url);
      }

      setIsLoading(false);
    } catch (e: any) {
      if (e?.response?.status === 400) {
        showError(e.response?.data?.message).then(() => {
          handleLogout();
        });
      } else if (e?.response?.status === 401) {
        showError(`Você não está autênticado!`).then(() => {
          handleLogout();
        });
      } else if (e?.response?.status === 403) {
        showError(e.response.data.message).then(async () => {
          handleLogout();
        });
      } else {
        setIsLoading(false);
      }
    }
  }, [handleLogout, history, isLogged, redirectUrl, updateAuth]);

  useEffect(() => {
    if (!loadingAuth) {
      if (isLogged && !initialize.current) {
        init();
      } else {
        setIsLoading(false);
      }
    }
  }, [init, isLogged, loadingAuth]);

  useEffect(() => {
    if (!isLogged) {
      initialize.current = false;
    }
  }, [isLogged]);

  if (isLoading) {
    return (
      <Flex
        flex={1}
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        justifyContent="center"
        alignItems="center"
      >
        <Lottie
          width={300}
          options={{
            animationData: lottieJson,
          }}
        >
          {null}
        </Lottie>
      </Flex>
    );
  }

  return children;
}

export default AuthProvider;
