import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";
import config from "infra/config";
import moment from "moment";
import app from "infra/packages/firebase";
import { useCurrentUnidade } from "core/features/auth/unidade.store";

const auth = getAuth(app);

const http = axios.create({
  baseURL: config.urlApi,
});

http.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const { unidade } = useCurrentUnidade.getState();

    if (unidade.id) {
      config.headers["unidade"] = unidade.id;
    }

    if (unidade.id && !config.headers?.tenant) {
      config.headers["tenant"] = unidade.tenantId;
    }

    config.headers["locale"] = "pt-BR";

    const token = await getToken();

    if (token && !config.headers["authorization"]) {
      config.headers["authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

export async function getToken() {
  const currentUser = auth.currentUser;

  let token = null;

  if (currentUser) {
    try {
      const tokenResult = await currentUser.getIdTokenResult();

      if (moment(tokenResult.expirationTime).isAfter(moment())) {
        token = tokenResult.token;
      } else {
        token = await currentUser.getIdToken(true);
      }
    } catch (err) {
      token = await currentUser.getIdToken(true); // TODO, rever isso pois esta fazendo refresh token todo momento
    }
  }

  return token;
}

export default http;
