import { PaginateQuery, PaginateResult } from "core/resources/types/shared";
import { useQuery } from "react-query";
import axios from "infra/http";
import { VotacaoDto } from "../typings";

function useAssembleias(data?: PaginateQuery) {
  const {
    page = 1,
    limit = 32,
    search = undefined,
  } = data ?? {
    page: 1,
    limit: 32,
    search: undefined,
  };
  return useQuery([`/mobile/v1/votacao-${page}-${limit}-${search}`], async () => {
    const res = await axios.get<PaginateResult<VotacaoDto>>(`/mobile/v1/votacao`, {
      params: {
        page,
        limit,
        search,
      },
    });

    return res.data;
  });
}

export default useAssembleias;
