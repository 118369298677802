import { Button, Container, Flex, Box, Image, Text } from "@chakra-ui/react";
import logo from "assets/images/logo-horizontal.png";
import bgLogin from "assets/images/background-login.svg";
import useConfirmarConvidadoPortaria from "core/features/portaria/useConfirmarConvidadoPortaria";
import colors from "core/resources/theme/colors";
import { showError } from "infra/helpers/alerta";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import InputCpfCnpj from "ui/components/Input/InputCpfCnpj";

function QrCodePortariaPage() {
  const route = useRouteMatch<{ portariaId: string }>();
  const { control, watch, handleSubmit } = useForm<{
    nome: string;
    documento: string;
  }>({
    defaultValues: {
      nome: "",
      documento: "",
    },
    reValidateMode: "onBlur",
  });

  const [qrCode, setQrCode] = useState<string | number | undefined>(undefined);

  const { mutate, isLoading } = useConfirmarConvidadoPortaria(
    route.params.portariaId
  );

  const onSubmit = ({
    nome,
    documento,
  }: {
    nome: string;
    documento: string;
  }) => {
    if (nome && documento) {
      mutate(
        {
          nome,
          documento,
        },
        {
          onSuccess: ({ data }) => {
            setQrCode(data.qrCode);
          },
          onError: (err: any) => {
            showError(err);
          },
        }
      );
    }
  };

  const form = watch();

  if (!route.params.portariaId) {
    return (
      <Flex
        backgroundImage={bgLogin}
        h="100dvh"
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        <Container
          maxW="sm"
          alignItems="center"
          pt={4}
          display="flex"
          flexDir="column"
        >
          <Image w="100%" height="auto" src={logo} alt="Logo Octopus" mb={4} />

          <Text fontWeight="bold" fontSize={20} textAlign="center" color="red">
            Link inválido!
          </Text>
          <Text fontWeight="bold" fontSize={20} textAlign="center" color="red">
            Peça um novo para quem autorizou a portaria.
          </Text>
        </Container>
      </Flex>
    );
  }

  return (
    <Flex
      backgroundImage={bgLogin}
      h="100dvh"
      bgSize="cover"
      bgRepeat="no-repeat"
    >
      <Container
        maxW="sm"
        justifyContent="center"
        pt={4}
        h="100dvh"
        display="flex"
        flexDir="row"
      >
        <CardDefault rounded={8} maxW={360} p={4} h={360}>
          <>
            <Image
              w="100%"
              height="auto"
              src={logo}
              alt="Logo Octopus"
              mb={4}
            />

            {qrCode ? (
              <>
                <Text color={colors.black700} my={4} textAlign="center">
                  QRCode Toppus
                </Text>
                <Flex justifyContent="center" alignItems="center" my="auto">
                  <QRCodeSVG value={`${qrCode}`} width={185} height={185} />
                </Flex>
              </>
            ) : null}

            {!qrCode ? (
              <Flex
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                flexDir="column"
              >
                <Text as="h3" fontWeight="bold" textAlign="center">
                  Atualizar dados
                </Text>
                <Text
                  fontSize={11}
                  color={colors.black600}
                  textAlign="center"
                  mb={2}
                >
                  Atualize seus dados para visualizar o QRCode!
                </Text>
                <Box mb={2}>
                  <Field
                    name="nome"
                    control={control}
                    rules={{ required: true }}
                    label="Nome"
                  />
                </Box>
                <Field
                  name="documento"
                  control={control}
                  rules={{ required: true }}
                  label="CPF/CNPJ"
                  render={({ field, isInvalid }) => (
                    <InputCpfCnpj {...field} isInvalid={isInvalid} />
                  )}
                />
                <Button
                  mt={4}
                  isLoading={isLoading}
                  disabled={isLoading || !form.nome || !form.documento}
                  type="submit"
                  colorScheme="blue"
                  ml="auto"
                >
                  Atualizar
                </Button>
              </Flex>
            ) : null}
          </>
        </CardDefault>
      </Container>
    </Flex>
  );
}

export default QrCodePortariaPage;
