import { BaseFiltroPaginate } from 'core/resources/enums/paginate';
import { PaginateQuery } from 'core/resources/types/shared';
import { useCallback, useState } from 'react'

function useFiltro() {
  const [filtro, setFiltro] = useState<PaginateQuery>({
    page: 1,
    limit: BaseFiltroPaginate.Limit,
  });
  
  const onChangeFiltro = useCallback((prop, value?: any) => {
    if (prop === undefined) {
      setFiltro({ limit: BaseFiltroPaginate.Limit, page: 1 });
    } else if (typeof prop !== "string") {
      setFiltro((prev: any) => ({ ...prev, ...prop }));
    } else {
      setFiltro((prev: any) => ({ ...prev, [prop]: value }));
    }
  }, []);

  return {
    filtro,
    setFiltro,
    onChangeFiltro,
  }
}

export default useFiltro
