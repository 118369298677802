import styled from "styled-components";

export const TableContainer = styled.div<{
  hideShadow?: boolean;
  flatBottom?: boolean;
}>`
  > div,
  tr,
  td {
    border-bottom: ${({ hideShadow }) =>
      hideShadow ? "none !important" : undefined};
  }

  .ant-table {
    border-radius: 16px;
    border-bottom-left-radius: ${({ flatBottom }) => (flatBottom ? 0 : 16)}px;
    border-bottom-right-radius: ${({ flatBottom }) => (flatBottom ? 0 : 16)}px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: ${({ flatBottom }) => (flatBottom ? 0 : 16)}px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: ${({ flatBottom }) => (flatBottom ? 0 : 16)}px;
  }

  .ant-table-container
    table
    > tbody
    > tr:last-child
    td:first-child.ant-table-cell {
    border-bottom-left-radius: ${({ flatBottom }) =>
      flatBottom ? 0 : 16}px !important;
  }

  .ant-table-container
    table
    > tbody
    > tr:last-child
    td:last-child.ant-table-cell {
    border-bottom-right-radius: ${({ flatBottom }) =>
      flatBottom ? 0 : 16}px !important;
  }
`;
