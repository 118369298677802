import { Container, Text, Box, Flex } from "@chakra-ui/layout";
import { Col, Row } from "antd";
import { useBreadcrumb } from "core/features/breadcrumbs";
import colors from "core/resources/theme/colors";
import { AiOutlineSound } from "react-icons/ai";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import MenuItem from "./components/MenuItem";

function HomePage() {
  const history = useHistory();

  useBreadcrumb([
    {
      name: "Página Inicial",
    },
  ]);

  return (
    <Container maxW="100%" pt="32px">
      <Flex>
        <Flex flex={1}>
          <Text fontSize="20px">Acesso Rápido</Text>
        </Flex>
      </Flex>
      <Box mt="32px">
        <Row gutter={32}>
          <Col md={6} sm={12}>
            <MenuItem
              title="Votação"
              onClick={() => history.push("/assembleias")}
              icon={<AiOutlineSound color={colors.secondary} fontSize={24} />}
            />
          </Col>
          <Col md={6} sm={12}>
            <MenuItem
              title="Boletos"
              onClick={() => history.push("/boletos")}
              icon={<FaMoneyCheckAlt color={colors.secondary} fontSize={24} />}
            />
          </Col>
        </Row>
      </Box>
    </Container>
  );
}

export default HomePage;
