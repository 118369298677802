import React from "react";
import ReactDOM from "react-dom";

// import './coi-serviceworker';
import "infra/boot";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
