import useHotjar from "react-use-hotjar";
import { useEffect } from "react";
import config from "infra/config";

function HotJarProvider({ children }: { children: any }) {
  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(config.hotjar, 6, false);
  }, [initHotjar]);

  return <>{children}</>;
}

export default HotJarProvider;
