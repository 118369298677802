import { useQuery } from "react-query";
import axios from "infra/http";
import { VotacaoDetalhesDto } from "../typings";

function useAssembleia(id: string) {
  return useQuery(
    [`/mobile/v1/votacao/${id}`],
    async () => {
      const res = await axios.get<VotacaoDetalhesDto>(
        `/mobile/v1/votacao/${id}`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useAssembleia;
