import { Box, Flex, Link, Image } from "@chakra-ui/react";
import { useRef } from "react";
import AddRemoveLineButton from "ui/components/Button/AddRemoveLineButton";
import OutlineButton from "ui/components/Button/OutlineButton";
import { getIconForFileExt, isPdf, isImage } from "infra/helpers/file";

interface InputFileProps {
  label: string;
  onChange: (file: File | null) => any;
  name?: string | undefined;
  value: File | string | undefined | null;
  accept?: string;
  showPreview?: boolean;
  showIcon?: boolean;
}

function InputFile({
  label,
  onChange,
  name,
  accept = "*",
  value,
  showPreview = false,
  showIcon = true,
}: InputFileProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onClick = () => {
    inputRef.current && inputRef.current.click();
  };

  const ext = name?.split(".").pop();
  const imageUrl =
    typeof value === "string"
      ? value
      : value
      ? URL.createObjectURL(value)
      : undefined;
  return (
    <>
      <Box as="span" display="none" maxW="100%">
        <input
          ref={inputRef}
          type="file"
          accept={accept}
          onChange={(ev) => {
            onChange(ev.target.files ? ev.target.files[0] : null);
          }}
        />
      </Box>

      <Flex flexDir="column">
        <Flex alignItems="center">
          {showIcon ? (
            <Flex h="32px" w="32px" alignItems="center" mr={1}>
              {getIconForFileExt(ext)}
            </Flex>
          ) : null}

          <OutlineButton onClick={onClick} maxW="100%" overflow="hidden">
            {value ? "Trocar" : label}
          </OutlineButton>

          {value ? (
            <AddRemoveLineButton
              containerProps={{ pt: 0, ml: 1 }}
              showAdd={false}
              onRemove={() => onChange(null)}
            />
          ) : null}
        </Flex>
        {value && ext && (isImage(ext) || isPdf(ext)) ? (
          <Box mt={2} ml={"36px"} flexDir="column">
            <Link
              textDecoration="underline"
              target="_blank"
              display="flex"
              alignItems="center"
              gridGap={1}
              rel="noopener noreferrer"
              href={imageUrl}
            >
              Abrir {isImage(ext) ? "imagem" : "PDF"}{" "}
              <span>
                <i className="mdi mdi-open-in-new" />
              </span>
            </Link>
            {showPreview ? (
              <Link
                target="_blank"
                mt={2}
                rel="noopener noreferrer"
                href={imageUrl}
              >
                <Image src={imageUrl} h="120px" w="120px" objectFit="cover" />
              </Link>
            ) : null}
          </Box>
        ) : null}
      </Flex>
    </>
  );
}

export default InputFile;
