import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export function init() {
  Sentry.init({
    dsn: "https://401543ce2b91409b93e7157c47dc240c@o882011.ingest.sentry.io/6324215",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
