// import { ZoomMtg } from "@zoomus/websdk";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { useCurrentAuth } from "core/features/auth/auth.store";
import { useWindowSize } from "infra/helpers/window";
import { generateSignature } from "infra/helpers/zoom";
import { useCallback, useState } from "react";

export function useZoom() {
  const { auth } = useCurrentAuth();

  const windowSize = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);

  const handleJoin = useCallback(
    async (data: { webinarId: string; senha: string }) => {
      setIsLoading(true);

      const client = ZoomMtgEmbedded.createClient();

      const meetingSDKElement = document.getElementById("meetingSDKElement");

      const percent = windowSize.width * 0.3;

      const w = windowSize.width - percent;

      console.log("width", w);

      client.init({
        debug: false,
        zoomAppRoot: meetingSDKElement!,
        language: "pt-PT",
        customize: {
          participants: {
            popper: {
              placement: "top",
            },
          },

          toolbar: {
            buttons: [{ text: "Olaa mundo", onClick: () => alert("Deu neh") }],
          },
          video: {
            isResizable: true,
            viewSizes: {
              default: {
                height: 500,
                width: windowSize.width - percent,
              },
              // ribbon: {
              //   height: 500,
              //   width: 500,
              // },
            },
            popper: {
              disableDraggable: true,
            },
          },
        },
      });

      const result = await generateSignature(data.webinarId);

      client.join({
        meetingNumber: data.webinarId,
        signature: result.signature,
        password: data.senha,
        userName: auth.nome,
        userEmail: auth.email,
        apiKey: result.apiKey,
      });

      setIsLoading(false);
    },
    [auth.email, auth.nome, windowSize.width]
  );

  // const handleJoin2 = useCallback(
  //   async (data: { webinarId: string; senha: string }) => {
  //     const meetingSDKElement = document.getElementById("meetingSDKElement");

  //     const result = await generateSignature(data.webinarId);

  //     console.log("init metting");

  //     try {
  //       ZoomMtg.init({
  //         leaveUrl: "http://localhost:3001",
  //         isSupportAV: true,
  //         debug: false,
  //         disableCORP: false,
  //         success: () => {
  //           // meetingSDKElement.style.zIndex = "1";
  //           console.log("sucesso");
  //           ZoomMtg.join({
  //             meetingNumber: data.webinarId,
  //             signature: result.signature,
  //             // passWord: result.webinarSenha,
  //             userName: auth.nome,
  //             userEmail: auth.email,
  //             apiKey: result.apiKey,
  //             error: () => {},
  //             success: () => {
  //               console.log("sucesso");
  //             },
  //           });
  //         },
  //         error: (err: any) => {
  //           console.log("erro", err);
  //         },
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   },
  //   [auth.email, auth.nome]
  // );

  return {
    handleJoin,
    isLoading,
  };
}
