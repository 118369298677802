import { useQuery } from "react-query";
import http from "infra/http";
import { BoletoPreviewRequest } from "../typings";

function useBoletoDetalhe(id: string) {
  return useQuery(
    [`/v1/boleto/${id}/detalhes`],
    async () => {
      const res = await http.get<BoletoPreviewRequest>(
        `/v1/boleto/${id}/detalhes`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useBoletoDetalhe;
