import { Pagination as AntdPagination, PaginationProps } from "antd";
import styled from "styled-components";

function Pagination(props: PaginationProps) {
  if (props.total === 0) return null;

  return (
    <Container>
      <AntdPagination size="small" {...props} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  .ant-pagination-item-active {
    border-radius: 8px;
  }
`;

export default Pagination;
