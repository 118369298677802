import React from "react";
import { BiRefresh } from "react-icons/bi";
import OutlineButton from "ui/components/Button/OutlineButton";

interface RefetchButtonProps {
  refetch?: () => any;
}

function RefetchButton({ refetch }: RefetchButtonProps) {
  return (
    <OutlineButton
      lineColor="white"
      color="white"
      bgColor="transparent"
      py={0}
      pt={0}
      onClick={refetch}
      gridGap={1}
      fontSize="13px"
      h="32px"
    >
      <BiRefresh color="white" fontSize="20px" /> Atualizar
    </OutlineButton>
  );
}

export default RefetchButton;
