import { useCallback, useState } from "react";

import history from "infra/helpers/history";
import { showSuccess } from "infra/helpers/alerta";
import { showFeedbackForError } from "infra/helpers/showFeedbackForError";
import http from "infra/http";


export default function useRecover() {
  const [isLoading, setIsLoading] = useState(false);

  const handleRecuperar = useCallback(async ({ email }) => {
    setIsLoading(true);

    try {
      await http.post(`v1/auth/recover-password`, {
        email,
      });

      await showSuccess("Foi enviado um email para recuperação de senha");

      history.push("/auth/login");
    } catch (e) {
      showFeedbackForError(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    handleRecuperar,
    isLoading,
  };
}
