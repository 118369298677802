import {
  AiOutlineFile,
  AiOutlineFileExcel,
  AiOutlineFileImage,
  AiOutlineFilePdf,
  AiOutlineFilePpt,
  AiOutlineFileText,
  AiOutlineFileWord,
} from "react-icons/ai";
import colors from "core/resources/theme/colors";

export const isImage = (ext: string) => {
  const imgExts = ["jpeg", "jpg", "png", "tiff", "webp", "jfif", 'gif'];
  return imgExts.includes(ext);
};

export const isPdf = (ext: string) => {
  return ext === "pdf";
};

export const isSvg = (ext: string) => {
  return ext === "svg";
};

export const getIconForFileExt = (ext: string | undefined) => {
  const iconProps = {
    size: 32,
    color: colors.blue300,
  };
  switch (ext?.toLowerCase()) {
    case "jpeg":
    case "jpg":
    case "png":
    case "tiff":
    case "webp":
    case "jfif":
      return <AiOutlineFileImage {...iconProps} />;
    case "pdf":
      return <AiOutlineFilePdf {...iconProps} />;
    case "doc":
    case "docx":
      return <AiOutlineFileWord {...iconProps} />;
    case "txt":
      return <AiOutlineFileText {...iconProps} />;
    case "csv":
    case "xls":
    case "xlsx":
      return <AiOutlineFileExcel {...iconProps} />;
    case "ppt":
    case "pptx":
      return <AiOutlineFilePpt {...iconProps} />;
    default:
      return <AiOutlineFile {...iconProps} />;
  }
};
