import colors from "core/resources/theme/colors";
import Button, { ButtonProps } from ".";

interface OutlineButtonProps extends ButtonProps {
  lineColor?: string;
  w?: string;
}
function OutlineButton({
  children,
  lineColor = colors.primary,
  w,
  ...rest
}: OutlineButtonProps) {
  return (
    <Button
      rounded={8}
      px={4}
      py={4}
      bgColor={colors.white}
      color={lineColor}
      borderColor={lineColor}
      borderWidth={1}
      display="flex"
      gridGap={2}
      fontSize={14}
      fontWeight="normal"
      w={w}
      _hover={{
        // color: colors.white,
        opacity: 0.8,
      }}
      _disabled={{
        opacity: 0.6,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default OutlineButton;
