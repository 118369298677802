import { Flex } from "@chakra-ui/react";
import { Modal } from "antd";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import FooterModal from "ui/components/Button/FooterModal";

interface ModalCameraProps {
  visible: boolean;
  onClose: () => any;
  onSubmit: (uri: string) => any;
}

function ModalCamera({ visible, onClose, onSubmit }: ModalCameraProps) {
  return (
    <Modal
      destroyOnClose
      width="600px"
      footer={
        <FooterModal
          registerAnother={false}
          setRegisterAnother={() => null}
          onSubmit={() => {}}
          onClose={onClose}
          isLoadingSave={false}
          isDisabled={false}
          showRegisterAnother={false}
          primaryButtonText=""
          showPrimaryButton={false}
        />
      }
      centered
      bodyStyle={{
        borderRadius: 16,
        height: "80dvh",
      }}
      visible={visible}
      onCancel={onClose}
      closable={false}
    >
      <Flex flexDir="column" justifyContent="center" h="100%">
        <Camera
          sizeFactor={0.75}
          imageCompression={0.95}
          idealFacingMode={FACING_MODES.USER}
          onTakePhoto={(dataUri) => {
            onSubmit(dataUri);
            onClose();
          }}
        />
      </Flex>
    </Modal>
  );
}

export default ModalCamera;
