import { Modal } from "antd";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { Button } from "@chakra-ui/button";
import useBoleto from "core/features/boleto/hooks/useBoleto";
import { QRCodeSVG } from "qrcode.react";
import { useLoading } from "ui/components/GlobalLoading";
interface ModalDetalhesProps {
  isOpenModal: boolean;
  onClose: () => any;
  id: string;
}

function ModalDetalhes({ isOpenModal, onClose, id }: ModalDetalhesProps) {
  const toast = useToast();
  const { data, isFetching, isLoading: isLoadingBoleto } = useBoleto(id);

  function handleClick() {
    navigator.clipboard
      .writeText(data?.codigoPix ? data?.codigoPix : "")
      .then(() => {
        toast({
          position: "bottom",
          description: "Código copiado com sucesso",
          status: "success",
        });
        onClose();
      });
  }

  useLoading(isLoadingBoleto || isFetching);

  return (
    <Modal
      centered
      visible={isOpenModal}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      width="600px"
    >
      <Box>
        {data?.codigoPix ? (
          <>
            <Flex justifyContent="center" mb={6}>
              <QRCodeSVG value={data?.codigoPix} />
            </Flex>
            <Flex flexDir="column" alignItems="center">
              <Text fontWeight="bold" fontSize="20px" mb={2}>
                Escaneie ou copie o QR CODE
              </Text>
              <Text color={colors.black700} fontSize="16px">
                {data?.codigoPix?.slice(0, 45) + "..."}
              </Text>
            </Flex>
          </>
        ) : (
          <Flex flexDir="column" alignItems="center">
            <Text color={colors.black700} fontSize="16px">
              Sem código Pix disponível
            </Text>
          </Flex>
        )}
        <Flex mt={6} justifyContent="center" gap={4}>
          <Button
            size="sm"
            variant="outline"
            colorScheme="blue"
            pr="20px"
            pl="20px"
            onClick={onClose}
          >
            Fechar
          </Button>
          <Button
            size="sm"
            colorScheme="facebook"
            pr="20px"
            pl="20px"
            onClick={handleClick}
            disabled={!data?.codigoPix}
          >
            Copiar código
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}

export default ModalDetalhes;
