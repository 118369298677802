import { Flex } from "@chakra-ui/react";

function SidebarContainer({ children }: { children: any }) {
  return (
    <Flex
      flexDir="column"
      borderBottomRadius="15px"
      backgroundColor="white"
      minH="600px"
      padding="15px"
      paddingTop="30px"
    >
      {children}
    </Flex>
  );
}

export default SidebarContainer;
