import { Skeleton } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import PerguntasBox from "../PerguntasBox";
import VotacaoBox from "../VotacaoBox";
import ResultadoBox from "../ResultadoBox";
import { VotacaoDetalhesDto } from "core/features/assembleia/typings/index";
import useVotar from "core/features/assembleia/hooks/useVotar";
import {
  showConfirm,
  showError,
  showErrorAxios,
  showSuccess,
} from "infra/helpers/alerta";
import { useLoading } from "ui/components/GlobalLoading";

function VotacaoTab({
  assembleia,
  onRefresh,
  changeQuestion,
  isFetching,
}: {
  assembleia: VotacaoDetalhesDto | undefined;
  changeQuestion: (id: string) => any;
  onRefresh: () => void;
  isFetching?: boolean;
}) {
  const [tabNow, setTabNow] = useState(0);
  const [selectedQ, setSelectedQ] = useState<string | null>(null);
  const { mutate: votar, isLoading: isLoadingVotar } = useVotar();

  const confirmarVoto = useCallback(
    async (idOption: any) => {
      const { isConfirmed } = await showConfirm(
        "Deseja realmente confirmar este voto?"
      );

      if (isConfirmed) {
        votar(
          {
            id: assembleia!.id,
            perguntaId: selectedQ!,
            opcoes: Array.isArray(idOption) ? idOption : [idOption],
          },
          {
            onError(err: any) {
              showErrorAxios(err);
            },
            async onSuccess() {
              await showSuccess("Voto feito com sucesso!");

              onRefresh();

              const mostraResultadoParcial = !!assembleia!.perguntas.find(
                (value) => value.id === selectedQ
              )?.mostraResultadoParcial;

              if (mostraResultadoParcial) {
                setTabNow(2);
              } else {
                setTabNow(0);
              }
            },
          }
        );
      }
    },
    [assembleia, onRefresh, selectedQ, votar]
  );

  const getPergunta = useCallback(
    (id?: string) => {
      let _id = id || selectedQ;

      return assembleia!.perguntas.find((value) => value.id === _id);
    },
    [assembleia, selectedQ]
  );

  const selecionarPergunta = useCallback(
    async (id: string) => {
      setSelectedQ(id);

      const pergunta = getPergunta(id);

      if (pergunta?.respondida) {
        if (pergunta.mostraResultadoParcial || pergunta.dataEncerramento) {
          changeQuestion(id);

          setTabNow(2);
        } else {
          showError(
            "Você já respondeu esta pergunta, os resultados estarão disponíveis em breve"
          );
        }
      } else {
        setTabNow(1);
      }
    },
    [changeQuestion, getPergunta]
  );

  useLoading(isLoadingVotar);

  return (
    <>
      {tabNow === 0 ? (
        assembleia ? (
          <PerguntasBox
            assembleia={assembleia}
            onClick={selecionarPergunta}
            refetch={onRefresh}
            isFetching={isFetching}
          />
        ) : (
          <Skeleton w="100%" h="400px" />
        )
      ) : null}

      {tabNow === 1 ? (
        <VotacaoBox
          perguntaId={selectedQ!}
          onReturn={() => setTabNow(0)}
          onConfirm={(id_opt) => {
            confirmarVoto(id_opt);
          }}
        />
      ) : null}

      {tabNow === 2 ? (
        <ResultadoBox pergunta={getPergunta()!} onReturn={() => setTabNow(0)} />
      ) : null}
    </>
  );
}

export default VotacaoTab;
