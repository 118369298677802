import { Menu } from "core/features/menu/typings/menu";
import { Drawer, Menu as MenuAntd } from "antd";
import { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Button } from "@chakra-ui/react";
import { MenuMobileWrapper } from "./styles";
import { useWindowSize } from "infra/helpers/window";

const { SubMenu } = MenuAntd;

function ResponsiveMenu({ items }: { items: Menu[] }) {
  const [open, setOpen] = useState(false);

  const windowSize = useWindowSize();

  const handleClick = (item: Menu) => {
    item.action?.();
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        mt="12px"
      >
        <MenuOutlined />
      </Button>
      <Drawer
        title="Menu"
        width={windowSize.width * 0.8}
        onClose={() => setOpen(false)}
        visible={open}
        bodyStyle={{ padding: 0 }}
      >
        <MenuMobileWrapper>
          <MenuAntd mode="inline">
            {items.map((item) => (
              <MenuLevel0 handleClick={handleClick} item={item} />
            ))}
          </MenuAntd>
        </MenuMobileWrapper>
      </Drawer>
    </>
  );
}

function MenuLevel0({
  item,
  handleClick,
}: {
  item: Menu;
  handleClick: (v: Menu) => void;
}) {
  if (!item.children.length) {
    return (
      <MenuAntd.Item
        onClick={() => handleClick(item)}
        icon={item.icon}
        key={item.title}
        style={{
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        {item.title}
      </MenuAntd.Item>
    );
  }

  return (
    <SubMenu
      style={{
        paddingLeft: 8,
        paddingRight: 8,
      }}
      key={item.title}
      icon={item.icon}
      title={item.title}
    >
      {item.children.map((child, i) => (
        <MenuLevel1 item={child} handleClick={handleClick} key={i} />
      ))}
    </SubMenu>
  );
}

function MenuLevel1({
  item,
  handleClick,
}: {
  item: Menu;
  handleClick: (v: Menu) => void;
}) {
  if (!item.children.length) {
    return (
      <MenuAntd.Item
        onClick={() => handleClick(item)}
        icon={item.icon}
        key={item.title}
      >
        {item.title}
      </MenuAntd.Item>
    );
  }

  return (
    <MenuAntd.ItemGroup key={item.title} title={item.title}>
      {item.children.map((child, i) => (
        <MenuAntd.Item
          icon={child.icon}
          onClick={() => handleClick(child)}
          key={child.title}
        >
          {child.title}
        </MenuAntd.Item>
      ))}
    </MenuAntd.ItemGroup>
  );
}

export default ResponsiveMenu;
