import create from "zustand";
import { persist } from "zustand/middleware";
import { Me } from "./typings/user";

interface AuthStore {
  auth: Me;
  isLogged: boolean;
  reset: () => void;
  update: (auth: Me, merge?: boolean) => void;
  updateIsLogged: () => void;
}

export const useCurrentAuth = create<AuthStore>(
  persist(
    (set) => ({
      auth: {} as Me,
      isLogged: false as boolean,
      updateIsLogged: () => {
        set((state) => ({ ...state, isLogged: true }));
      },
      update: (auth: Me, merge?: boolean) => {
        set((state) => ({
          ...state,
          auth: merge ? { ...state.auth, ...auth } : auth,
          isLogged: true,
        }));
      },
      reset: () => {
        set((state) => ({
          ...state,
          auth: {} as Me,
          isLogged: false,
        }));
      },
    }),
    {
      name: "@administradora-digital-user",
      getStorage: () => localStorage,
    }
  )
);
