import { Box, Skeleton } from "@chakra-ui/react";
import PerguntasSelect from "../PerguntasSelect";
import { VotacaoDetalhesDto } from "features/assembleia/typings/index";
import Toolbar from "components/Toolbar";
import SidebarContainer from "../SitebarContainer";
import RefetchButton from "ui/pages/App/Assembleia/Detalhes/components/RefetchButton";

function PerguntasBox({
  assembleia,
  onClick,
  refetch,
  isFetching,
}: {
  assembleia: VotacaoDetalhesDto;
  onClick: (id: string) => void;
  refetch?: () => any;
  isFetching?: boolean;
}) {
  return (
    <Box>
      <Toolbar
        title="Perguntas"
        rightElement={
          <Box ml="auto">
            <RefetchButton refetch={refetch} />
          </Box>
        }
      />
      <SidebarContainer>
        {isFetching ? (
          <>
            <Skeleton rounded="16px" w="100%" h="64px" mb={5} />
            <Skeleton rounded="16px" w="100%" h="64px" mb={5} />
            <Skeleton rounded="16px" w="100%" h="64px" mb={5} />
          </>
        ) : (
          assembleia.perguntas.map((item, index) => (
            <PerguntasSelect
              pergunta={item}
              ordem={index}
              onClick={onClick}
              key={index}
            />
          ))
        )}
      </SidebarContainer>
    </Box>
  );
}

export default PerguntasBox;
