import styled from "styled-components";
import colors from "core/resources/theme/colors";
import bgLogin from "assets/images/background-login.svg";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex: 1;
  background-color: ${colors.b1};
  background-image: url(${bgLogin});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left bottom;
`;

export const CardLogin = styled.div`
  max-width: 400px;
  padding: 16px;
  width: 100%;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.05);
  position: relative;
  padding-top: 70px;
`;
