import { Box, Button, Flex } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import useAssembleia from "core/features/assembleia/hooks/useAssembleia";
import { useRouteMatch } from "react-router-dom";

import { MettingContainer } from "./styles";
import { useLoading } from "ui/components/GlobalLoading";
import { MdPlayCircle } from "react-icons/md";
import { useZoom } from "core/features/zoom/hooks";

function ZoomPage() {
  const route = useRouteMatch<any>();

  const [startCall, setStartCall] = useState(false);

  const { data, isFetching } = useAssembleia(route.params.votacaoId);

  const { handleJoin, isLoading } = useZoom();

  const _handleJoin = useCallback(async () => {
    if (!data) {
      return;
    }

    await handleJoin({
      senha: data.webinarSenha,
      webinarId: data.webinarId,
    });

    setStartCall(true);
  }, [data, handleJoin]);

  useEffect(() => {
    _handleJoin();
  }, [_handleJoin]);

  useLoading(isFetching);

  return (
    <>
      {!isFetching && !startCall && data?.webinarId ? (
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          bg="gray.600"
          w="100%"
          h="100vh"
        >
          <Button gap="8px" isLoading={isLoading} onClick={() => _handleJoin()}>
            <MdPlayCircle /> Entrar no Video
          </Button>
        </Flex>
      ) : null}
      <MettingContainer>
        <Box id="meetingSDKElement"></Box>
      </MettingContainer>
    </>
  );
}

export default ZoomPage;
