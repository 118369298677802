import { useLocation } from "react-router-dom";

import useLogin from "core/features/auth/login/useLogin";
import { useEffect } from "react";
import GlobalLoading from "ui/components/GlobalLoading";
import { useCurrentAuth } from "core/features/auth/auth.store";

function LoginToken() {
  const { isLogged } = useCurrentAuth();

  const { handleLoginToken } = useLogin();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const loginToken = params.get("loginToken") as string;
  const redirectUrl = params.get("redirectUrl") as string;

  useEffect(() => {
    (async () => {
      const url = `/${redirectUrl}`.replace("//", "/");

      if (loginToken && !isLogged) {
        const success = await handleLoginToken(loginToken);

        if (success) {
          window.location.href = url;
        }
      } else if (redirectUrl) {
        window.location.href = url;
      }
    })();
  }, [handleLoginToken, loginToken, isLogged, redirectUrl]);

  return <GlobalLoading />;
}

export default LoginToken;
