import { Flex, Tag } from "@chakra-ui/react";
import { ImQrcode } from "react-icons/im";
import { Tooltip } from "antd";

function TagPix({
  onClick,
  id,
  disabled,
}: {
  onClick: (id: string) => any;
  id: string;
  disabled?: boolean;
}) {
  return (
    <Tooltip
      title={disabled ? "Pix não disponível" : "Pague com Pix"}
      placement="bottom"
    >
      <Tag
        size="md"
        variant="outline"
        colorScheme="blue"
        onClick={() => (disabled ? null : onClick(id))}
        cursor={disabled ? "not-allowed" : "pointer"}
        opacity={disabled ? 0.7 : 1}
      >
        <Flex gap={2}>
          <ImQrcode fontSize="18px" />
          Pix
        </Flex>
      </Tag>
    </Tooltip>
  );
}

export default TagPix;
