import { trackEvent } from "infra/helpers/event";
import create from "zustand";
import { persist } from "zustand/middleware";
import { UserUnidade } from "./typings/user";

interface UnidadeStore {
  unidade: UserUnidade;
  selecionar: (unidade: UserUnidade) => void;
  reset: () => void;
}

export const useCurrentUnidade = create<UnidadeStore>(
  persist(
    (set) => ({
      unidade: {} as UserUnidade,
      selecionar: (unidade: UserUnidade) => {
        trackEvent("unidade.selecionada", {
          id: unidade.id,
          nome: unidade.numero,
        });

        set((state) => ({ ...state, unidade }));
      },
      reset: () => {
        set((state) => ({ ...state, unidade: {} as UserUnidade }));
      },
    }),
    {
      name: "@octopus-unidade",
      getStorage: () => localStorage,
    }
  )
);
