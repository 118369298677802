import { Input } from "@chakra-ui/input";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { useCurrentAuth } from "core/features/auth/auth.store";
import useLogout from "core/features/auth/logout/useLogout";
import useSaveProfile from "core/features/auth/profile/useSaveProfile";
import { useCurrentTenant } from "core/features/auth/tenant.store";
import colors from "core/resources/theme/colors";
import history from "infra/helpers/history";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineBank, AiOutlineCaretDown } from "react-icons/ai";
import { BsArrowLeftRight } from "react-icons/bs";
import { MdFilterDrama, MdPowerOff } from "react-icons/md";
import HeaderMenuItem from "./HeaderMenuItem";
import { ProfileImage } from "./styles";
import Can from "../../Can";
import TenantList from "./UnidadeList";
import { Button, Image } from "@chakra-ui/react";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { TipoTenantEnum } from "core/resources/enums/tenant";
import { Me } from "core/features/auth/typings/user";
import { useCurrentUnidade } from "core/features/auth/unidade.store";

function MenuUser() {
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");

  const { auth } = useCurrentAuth();
  const { tenant } = useCurrentTenant();
  const { unidade } = useCurrentUnidade();

  const { handleLogout } = useLogout();

  const { reset } = useForm<Me>({
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (openModal && auth?.email) {
      reset({
        nome: auth.nome,
        imagemUrl: auth.imagemUrl,
        email: auth.email,
      });
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, openModal]);

  const { isSuccess } = useSaveProfile();

  useEffect(() => {
    if (isSuccess) {
      setOpenModal(false);
    }
  }, [isSuccess]);

  if (!auth.email) {
    return null;
  }

  return (
    <>
      <Flex alignItems="center" pr="16px">
        <Popover>
          <PopoverTrigger>
            <Flex
              pos="relative"
              border={`1px solid ${colors.primary}`}
              backgroundColor={colors.primary}
              rounded="6px"
            >
              <HeaderMenuItem
                py="3px"
                submenuOpen={false}
                onCloseSubmenu={() => null}
                onOpenSubmenu={() => null}
                color={colors.primary}
                item={{
                  title: (
                    <Flex>
                      <Flex pos="relative" pr="8px" top="3px">
                        <BsArrowLeftRight color={colors.white} />
                      </Flex>
                      <Flex color={colors.white}>
                        {unidade?.numero} - {unidade.tenantNome}
                      </Flex>
                    </Flex>
                  ) as any,
                  children: [],
                  visible: () => true,
                  action: () => {
                    // selecionarTenant(undefined);
                    // history.push("/auth/selecionar-empresa");
                  },
                }}
              />
            </Flex>
          </PopoverTrigger>

          <PopoverContent>
            <PopoverHeader>
              <Input
                placeholder="Buscar"
                onChange={(v) => setSearch(v.target.value)}
                value={search}
              />
            </PopoverHeader>
            <PopoverBody overflowY="auto" maxH="80vh">
              <TenantList search={search} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>

      <Menu>
        <MenuButton>
          <Flex alignItems="center">
            <ProfileImage>
              {auth.imagemUrl ? (
                <img src={auth.imagemUrl} alt={auth.nome ?? ""} />
              ) : null}
              {!auth.imagemUrl ? (
                <Text
                  rounded="18px"
                  textAlign="center"
                  py="4px"
                  fontSize="18px"
                  w={"35px"}
                  h={"35px"}
                  fontWeight="bold"
                  color={colors.primary}
                  bg={colors.blue100}
                >
                  {(auth.nome || auth.email)?.split("")[0]}
                </Text>
              ) : null}
            </ProfileImage>
            <Box ml={1}>
              <AiOutlineCaretDown size={14} color={colors.black700} />
            </Box>
          </Flex>
        </MenuButton>

        <MenuList>
          <Flex
            p="16px"
            pb="24px"
            mb="8px"
            borderBottom={"1px solid #efefef"}
            alignItems="center"
            flexDir={"column"}
          >
            <Box>
              {auth.imagemUrl ? (
                <Image
                  w="50px"
                  h="50px"
                  rounded={"full"}
                  src={auth.imagemUrl}
                  alt={auth.nome || auth.email}
                />
              ) : null}

              {!auth.imagemUrl ? (
                <Text
                  rounded="25px"
                  textAlign="center"
                  py="7px"
                  fontSize="24px"
                  w={"50px"}
                  h={"50px"}
                  fontWeight="bold"
                  color={colors.primary}
                  bg={colors.blue100}
                >
                  {(auth.nome || auth.email)?.split("")[0]}
                </Text>
              ) : null}
            </Box>

            <Text lineHeight={"0"} mt="16px">
              {auth.nome}
            </Text>
            <Text lineHeight={"0"} mt="16px" fontSize="10px">
              {auth.email}
            </Text>
            <Box mt="16px">
              <Button
                size="xs"
                fontWeight={"normal"}
                variant={"outline"}
                onClick={() => history.push("/editar-perfil")}
              >
                Editar meu perfil
              </Button>
            </Box>
          </Flex>

          <Can permission={PermissaoEnum.Backoffice.Condominios.Visualizar}>
            <MenuItem
              icon={<MdFilterDrama fontSize={16} />}
              onClick={() => history.push("/condominio")}
            >
              Condomínios
            </MenuItem>
          </Can>

          <Can permission={PermissaoEnum.Backoffice.Admin.Clientes.Visualizar}>
            <MenuItem
              icon={<MdFilterDrama fontSize={16} />}
              onClick={() => history.push("/clientes")}
            >
              Clientes
            </MenuItem>
          </Can>

          <Can permission={PermissaoEnum.Backoffice.PerfilEmpresa.Editar}>
            <MenuItem
              icon={<AiOutlineBank fontSize={16} />}
              onClick={() => history.push("/empresa")}
            >
              Dados da Empresa
            </MenuItem>
          </Can>

          <hr />

          {tenant.tipoTenantId === TipoTenantEnum.Condominio ? (
            <MenuItem
              icon={<MdFilterDrama fontSize={16} />}
              onClick={() => {
                history.push(`/meu-condominio`);
              }}
            >
              Dados do Condomínio
            </MenuItem>
          ) : null}

          <MenuItem
            icon={<MdPowerOff fontSize={16} />}
            onClick={() => handleLogout()}
          >
            Sair
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

export default MenuUser;
