import { Route, Switch, Redirect } from "react-router-dom";

import LoginPage from "./Login";
import RegisterPage from "./Register";
import RecoverPage from "./Recover";
import ResetPage from "./Reset";
import FirebaseActions from "./FirebaseActions";
import { useCurrentAuth } from "core/features/auth/auth.store";
import history from "infra/helpers/history";

function Auth() {
  const { isLogged } = useCurrentAuth();

  if (isLogged && !history.location.pathname.includes("auth")) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/register" component={RegisterPage} />
      <Route path="/auth/recover" component={RecoverPage} />
      <Route path="/auth/reset" component={ResetPage} />
      <Route path="/auth/firebase-actions" component={FirebaseActions} />
    </Switch>
  );
}

export default Auth;
