import { BrowserRouter, Route, Router } from "react-router-dom";
import AntdProvider from "infra/packages/antd";
import ChakraUiProvider from "infra/packages/@chakra-ui";
import { ReactQueryProvider } from "infra/packages/react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
// import config from "infra/config";
import { QueryParamProvider } from "use-query-params";
import AuthProvider from "core/features/auth/auth.context";
import MixPanelProvider from "infra/packages/mixpanel";
import GlobalLoading from "ui/components/GlobalLoading";
import Pages from "ui/pages";
import HotJarProvider from "infra/packages/hotjar";
import { PhotoProvider } from "react-photo-view";
import history from "infra/helpers/history";

function App() {
  return (
    <BrowserRouter>
      <Router history={history}>
        <AppProviders />
      </Router>
    </BrowserRouter>
  );
}

function AppProviders() {
  return (
    <HotJarProvider>
      <ReactQueryProvider>
        <ChakraUiProvider>
          <AntdProvider>
            <QueryParamProvider ReactRouterRoute={Route}>
              <AuthProvider>
                <MixPanelProvider>
                  <PhotoProvider>
                    <>
                      <GlobalLoading />

                      <Pages />
                      {/* 
                      {config.isDev && (
                        <ReactQueryDevtools initialIsOpen={false} />
                      )} */}
                    </>
                  </PhotoProvider>
                </MixPanelProvider>
              </AuthProvider>
            </QueryParamProvider>
          </AntdProvider>
        </ChakraUiProvider>
      </ReactQueryProvider>
    </HotJarProvider>
  );
}

export default App;
