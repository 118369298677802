import { Checkbox } from "@chakra-ui/checkbox";
import { Box } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import OutlineButton from "ui/components/Button/OutlineButton";
import PrimaryButton from "ui/components/Button/PrimaryButton";

interface FooterModalProps {
  onSubmit: () => any;
  isDisabled: boolean;
  isLoadingSave: boolean;
  registerAnother?: boolean;
  onClose?: () => any;
  setRegisterAnother?: (v: boolean) => void;
  showRegisterAnother?: boolean;
  primaryButtonText?: string;
  buttonCancelarText?: string;
  primaryButtonBgColor?: string;
  showPrimaryButton?: boolean;
}

function FooterModal({
  isLoadingSave,
  isDisabled,
  registerAnother = true,
  setRegisterAnother,
  onSubmit,
  onClose,
  showRegisterAnother = true,
  primaryButtonText = "Salvar",
  buttonCancelarText = "Cancelar",
  primaryButtonBgColor = colors.primary,
  showPrimaryButton = true,
}: FooterModalProps) {
  return (
    <Box
      mt="32px"
      justifyContent="flex-end"
      d="flex"
      gridGap="8px"
      flexDir="row"
      alignItems="center"
    >
      {showRegisterAnother ? (
        <Checkbox
          mr="auto"
          isChecked={registerAnother}
          onChange={() => setRegisterAnother?.(!registerAnother)}
        >
          Cadastrar outra após salvar
        </Checkbox>
      ) : null}

      {onClose ? (
        <OutlineButton type="button" onClick={onClose} minWidth="120px">
          {buttonCancelarText || "Cancelar"}
        </OutlineButton>
      ) : null}

      {showPrimaryButton ? (
        <PrimaryButton
          type="button"
          isLoading={isLoadingSave}
          onClick={onSubmit}
          minWidth={"120px"}
          isDisabled={isDisabled || isLoadingSave}
          cursor={isDisabled || isLoadingSave ? "not-allowed" : undefined}
          bgColor={primaryButtonBgColor}
        >
          {primaryButtonText}
        </PrimaryButton>
      ) : null}
    </Box>
  );
}

export default FooterModal;
